<div class="cmx-login {{showChrome?'chromed':'unchromed'}}">
    <div *ngIf="showChrome" class="cmx-login__header">
          <h1 class="cmx-login__header-logo " [ngStyle]="logoStyles">{{companyName}}</h1>
    </div>
    <div class="cmx-login__content">
        <h2 *ngIf="showChrome" class="cmx-login__content-title">{{title}}</h2>
        <div *ngIf="loginFailed" class="cmx-login__content-message-error" id="cmx-login-form-error-msg" id="cmx-login-form-error-msg">
            {{ translationService.pt(errorMsg) }} {{nextLoginAllowed}}
        </div>
        <form class="cmx-login__content-form" [formGroup]="loginForm" data-tid="cmx-login-form" (ngSubmit)="authenticate()">
            <cwc-ld-input
              class="cmx-login__input"
              type="text"
              formControlName="username"
              aria-describedby="usernameHelp"
              [label]="translationService.pt('views.login.username')"
              [placeholder]="translationService.pt('views.login.usernamePlaceholder')"
              data-tid="cmx-login-form-username-field"
              id="cmx-login-form-username-field"
              [rtl]="rtl ? 'true' : 'false'"
              designVersion="{{designVersion}}"
              [ngClass]="{'v2': designVersion === 'v2'}"
            ></cwc-ld-input>
            <cwc-ld-input
              class="cmx-login__input"
              type="password"
              name="password"
              formControlName="password"
              clear-on-edit="false"
              [label]="translationService.pt('views.login.password')"
              [placeholder]="translationService.pt('views.login.passwordPlaceholder')"
              aria-describedby="passwordHelp"
              data-tid="cmx-login-form-password-field"
              id="cmx-login-form-password-field"
              allow-password-show="true"
              [rtl]="rtl ? 'true' : 'false'"
              designVersion="{{designVersion}}"
              [ngClass]="{'v2': designVersion === 'v2'}"
            ></cwc-ld-input>
            <div class="cmx-login__content-forgot-pwd-link">
                <cwc-button
                  #forgotPassword
                  data-tid="cmx-login-form-forgot-password-link"
                  id="cmx-login-form-forgot-password-link"
                  (click)="goToForgotPassword()"
                  variant="link"
                >
                  {{ translationService.pt('views.login.forgot_password') }}
                </cwc-button>
            </div>
            <div class="cmx-login__submit">
              <cwc-button
                [rtl]="rtl?true:false"
                disabled="{{disabledSubmit()}}"
                data-tid="cmx-login-form-submit-btn"
                id="cmx-login-form-submit-btn"
                (cwcClick)="authenticate()"
                designVersion="{{designVersion}}"
                size="{{designVersion === 'v2' ? 'small' : 'regular' }}"
                variant="regular-block"
              >
                {{ translationService.pt('views.login.btn_login') }}
              </cwc-button>
            </div>
        </form>
    </div>
</div>

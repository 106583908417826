export class SearchFunctions {
    public filterData(items: any[], filter: { [x: string]: string; customerDesc?: string; customerCode?: string; jobsiteCode?: string; jobsiteDesc?: string; regionCode?: string; fullName?: string; }) {
        if (filter && Array.isArray(items)) {
            const filterKeys = Object.keys(filter);
            return items.filter((item) => {
                return filterKeys.some((keyName) => {
                    return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === '';
                });
            });
        } else {
            return items;
        }
    }
}

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DCMConstants } from '../helpers/DCM.constants';
import { CmxHttpClient } from './http-client.service';
import { CountryConfiguration } from '../models/countryConfiguration';
import { CountryCallingCode } from '../models/countryCallingCode';
import { Observable } from 'rxjs';

@Injectable()
export class CountryService {

    constructor(private cmxHttp: CmxHttpClient) {
    }

    public getCountryConfigurationByParameter(countryCode: string, paramDescription: string) {
        const appCode = DCMConstants.DCM_USER_MANAGEMENT_APP_CODE;
        const uri = `v6/ce/countries/configuration?countryCode=${countryCode}&applicationCode=${appCode}&parameterDescription=${paramDescription}`;

        return this.cmxHttp.get(uri)
            .pipe(map((res) => res && res['settings'] ? res['settings'] : []));
    }

    getCountriesByAppCountry(countryCode: string, aplicationCode: string) {
        const uri = `v6/ce/countries?countryCode=${countryCode}&applicationCode=${aplicationCode}`;
        return this.cmxHttp.get<CountryConfiguration[]>(uri)
            .pipe(map((res) => res ? res['countries'] : []));
    }

    getCountries(countryCode: string) {
        const uri = countryCode ? `v6/ce/countries?countryCode=${countryCode}` : `v6/ce/countries`;
        return this.cmxHttp.get<CountryConfiguration[]>(uri)
            .pipe(map((res) => res ? res['countries'] : []));
    }

    getCountryAreaCodes(): Observable<CountryCallingCode[]> {
      const uri = `v4/gm/countries?countryCallingCode=true`;
      return this.cmxHttp.get<CountryCallingCode[]>(uri).pipe(map((res) => res ? res['countries'].map(country => ({
        ...country,
        countryCode: country.countryCode.trim(),
        countryCallingCode: `+${country.countryCallingCode.trim()} `,
        fullCode: `+${country.countryCallingCode} (${country.countryCode.trim()})`
      })) : []));

    }
}

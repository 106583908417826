import { Injectable } from '@angular/core';
import { CountlyService } from '../../../../../../helpers-v1';

@Injectable()
export class CountlyEvents {
  constructor(
    private countlyService: CountlyService
  ) {}

  public onChangeForAnalytics(eventName, optionId) {
    const event = this.createEvent(eventName, optionId );
    this.countlyService.addBulkVariables(event);
  }

  /**
   * Creating event for analytics to track time range options clicks
   * @param eventName event name
   * @param optionId option which has been selected
   */
    private createEvent(eventName: string, optionId: string) {
      const country = sessionStorage.getItem('country');
      const customer = JSON.parse(sessionStorage.getItem('user_customer'));
      const event = {
        event: eventName,
        customerCode: customer.customerCode,
        customerName: customer.customerDesc,
        country: country,
        optionId: optionId
      };
      return event;
    }
}

import { Injectable } from '@angular/core';
import {
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivate
} from '@angular/router';
import { DocumentService, UserService } from '../services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionService } from 'src/app/angular-services-v7/session.service';
import { DCMConstants } from '../helpers/DCM.constants';

@Injectable()
export class TermsConditionsGuard implements CanActivate {

    private country: string;

    constructor(
        private router: Router,
        private documentService: DocumentService,
        private sessionService: SessionService,
        private userService: UserService,
    ) {
        this.country = sessionStorage.getItem('country');
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (!this.sessionService.isLoggedIn) {
            return of(false);
        } else if (state.url === '/dashboard' || state.url.indexOf('/dashboard?returnUrl') > -1) {
            return of(true);
        } else {
            const documentGroup = localStorage.getItem('platform') !== null ? 'SECM-EXT_LEGAL' : 'SECM_LEGAL';
            return this.documentService.getDocumentVersions(this.country, documentGroup).pipe(map((documentVersions) => {
                let signed = true;
                if (documentVersions) {

                  //Search for ThermOfUse that requires signing regardless of version
                  let fourDocs: any[] = documentVersions.filter(doc => doc.configuration.document.documentCode === DCMConstants.AGREEMENT_CAT.TermsOfUse
                    && doc.requireSignature === true);
                  if (!fourDocs.length) {
                    fourDocs = documentVersions;
                  }

                  // Get all documents that require signature.
                  const requireSignature = fourDocs
                    .filter(version => version.requireSignature && !version.hasOwnProperty('signatureInfo'));

                  if (requireSignature.length > 0) {
                      this.router.navigate(['/dashboard'], { queryParams: { returnUrl: state.url } });
                  }

              } else {
                  signed = false;
                  this.router.navigate(['/dashboard'], { queryParams: { returnUrl: state.url } });
              }
              this.userService.setTermsAndConditionsSigned(signed);
              return signed;
            }, (error: any) => {
                return of(false);
            }));

        }
    }
}

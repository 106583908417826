import { Pipe, PipeTransform } from '@angular/core';
import { ILanguage } from './../interfaces/language.interface';
import { DateFormatterService } from './../services/date-formatter.service';
import { TranslationService } from './../services/translation.service';

@Pipe({
  name: 'cmxDate',
  pure: true,
})
export class CmxDatePipe implements PipeTransform {
  private value = '';
  private monthsNames: string[] = [];
  private daysNames: string[] = [];

  constructor(
    private translation: TranslationService,
    private dateFormatter: DateFormatterService
  ) {}

  public transform(
    $value: Date | string | number,
    $countryCode?: string
  ): string {
    try {
      if (typeof $value === 'number') {
        $value = new Date($value);
      }
      if (typeof $value === 'string') {
        $value = this.dateFormatter.getDateObject($value);
      }
      if ($countryCode === undefined || $countryCode === null) {
        $countryCode = sessionStorage.getItem('country');
      }
      if ($value !== undefined && $value instanceof Date) {
        const $currentLanguage: string = localStorage.getItem('language');
        const $lang: ILanguage = this.translation.getLanguage(
          $currentLanguage ? $currentLanguage : 'en_US'
        );
        this.daysNames = $lang.dayNames.split(',');
        this.monthsNames = $lang.monthNames.split(',');
        this.value = this.dateFormatter.format(
          $value,
          $lang.formatDate,
          this.daysNames,
          this.monthsNames
        );
      } else {
        console.error('Date trying to transform: ', $value);
        this.value = '';
      }
    } catch ($exception) {
      console.error('Date trying to transform: ', $value);
      console.error($exception);
      this.value = '';
    }
    return this.value;
  }
}

import { Response } from '@angular/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpCemex } from '../angular-services-v7/http.service';

// Providers


export class EnvironmentStructure {
    static JSON = new EnvironmentStructure('JSON', { fileType: 'json', query: 'json' });
    static Plain = new EnvironmentStructure('Plain', { fileType: 'js', query: 'ds' });

    private constructor(private _key: string, private _value: any) {}

    get fileType() { return this._value.fileType; }

    get query() { return this._value.query; }
}

@Injectable()
export class EnvironmentService {
    constructor(private http: HttpCemex) {}

    public getEnvironmentConfig(appCode: string, structure: EnvironmentStructure ): Observable<string> {
        const isLocalhost = window.location.hostname.indexOf('localhost') > -1;
        const resource = isLocalhost ? `env-${appCode}.${structure.fileType}` : 'env.js';
        return this.http.get(`/conf-env/${resource}?${structure.query}=${appCode}`)
            .pipe(map((res: Response) =>
                structure === EnvironmentStructure.JSON ? JSON.parse(res.text()) : res.text()
            ), catchError(this.handleError));
    }

    private handleError(error: Response | any) {
        return throwError(error);
    }
}

import { Injectable } from '@angular/core';
import { RequestOptions, Headers, Response } from '@angular/http';
import { Observable, throwError } from 'rxjs/';
import { catchError, map } from 'rxjs/operators';
import { HttpCemex } from '../angular-services-v7/http.service';
import { ProjectSettings } from '../angular-services-v7/project.settings';

// Providers


@Injectable({
  providedIn: 'root'
})

export class PasswordService {
    private options = new RequestOptions();
    constructor(
        private http: HttpCemex,
        private projectEnv: ProjectSettings,
    ) {
        this.options.headers = new Headers();
        this.options.headers.append('Content-Type', 'application/json');
    }

    public resetPassword(request: any): Observable<Response> {
        return this.http.post(this.projectEnv.getBaseOrgEnvPath() +
            'v5/secm/user/resetpassword', request, this.options)
            .pipe(map((res: Response) => res.json()),
                catchError(err => this.handleError(err)));
    }

    public requestResetPassword(): Observable<Response> {
        return this.http.post(this.projectEnv.getBaseOrgEnvPath() +
            'v5/gm/passwords/reset?userAccount='+sessionStorage.getItem('userAccount'), null)
            .pipe(map((res: Response) => res),
                catchError(err => this.handleError(err)));
    }

    public resetPasswordByTemporaryPassword(request: any): Observable<Response> {
        return this.http.post(this.projectEnv.getBaseOrgEnvPath() +
            'v6/secm/passwords/reseted?isTemporal=true', request, this.options)
            .pipe(map((res: Response) => res),
                catchError(err => this.handleError(err)));
    }

    public changePassword(otp: string, password: string, country: string, userAccount: string): Observable<Response> {
        const data = { token: otp, password, country, userAccount };
        return this.http.post(this.projectEnv.getBaseOrgEnvPath() +
            'v5/secm/user/changePassword/token', data, this.options)
            .pipe(map((res: Response) => res.json()),
                catchError(err => this.handleError(err)));
    }

    public requestPasswordChange(email: string): Observable<Response> {
        return this.http.get(this.projectEnv.getBaseOrgEnvPath() +
            'v5/secm/user/changePassword?' + 'userId=' + email, this.options)
            .pipe(map((res: Response) => res.json()),
                catchError(err => this.handleError(err)));
    }

    public getUserProfile(token: string, countryCode: string): Observable<any> {
        return this.http.get(this.projectEnv.getBaseOrgEnvPath() + 'v6/secm/users/token?tokenId=' + token + '&country=' + countryCode)
            .pipe(map((res: any) => res.json()),
                catchError((error: any) => this.handleError(error)));
    }

    public getRulesPassword(countryCode: string) {
        return this.http.get(this.projectEnv.getBaseOrgEnvPath() +
            'v6/secm/passwords/rules?country=' + countryCode)
            .pipe(map((data: Response) => data.json()),
                catchError(this.handleError));
    }

    private handleError(error: Response) {
        return throwError(error || 'Server error');
    }
}

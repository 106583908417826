import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CmxInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const language = localStorage.getItem('language') || 'en';
    const accessToken = sessionStorage.getItem('access_token');
    const jwtApp = sessionStorage.getItem('jwt') || '';
    const noAuth = req.headers.keys().indexOf('No-Auth') > -1;

    const cmxHeaders = {
      Accept: 'application/json',
      'App-Code': 'Foreman_App',
      'Accept-Language': language,
      'x-ibm-client-id': window['CLIENT_ID'] // This is for params request
    };

    if (!noAuth) {
      cmxHeaders['Authorization'] = `Bearer ${accessToken}`;
      cmxHeaders['jwt'] = jwtApp;
    }

    const headerKeys = req.headers.keys() || [];
    for (const key of headerKeys) {
      cmxHeaders[key] = req.headers.get(key);
    }

    req = req.clone({
      setHeaders: cmxHeaders
    });

    return next.handle(req);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../angular-localization-v7/services/translation.service';

@Pipe({
  name: 'userPosition',
  pure: true
})
export class UserPositionPipe implements PipeTransform {

  constructor(private translationService: TranslationService) { }

  transform(value: string, countryCode: any): string {
    if (!value) {
      return value;
    }
    if (value === '') {
      return value;
    }
    if(value.match(/.*-\d{2}$/)) {
      return this.translationService.pt('views.crm.position.' + countryCode.countryCode + '.' + value)
    }
    else {
      return value;
    }
  }
}

import { Injectable } from '@angular/core';
import { ICountryConfig, ICountryParameters } from '../angular-types-v2';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpCemex } from './http.service';
import { ProjectSettings } from './project.settings';
import { SessionService } from './session.service';

export enum CountryParametersDesc {
  HelpCenterEmail = 'SECM_TEMPLATE_HELP_CENTER_EMAIL',
  HelpCenterPhoneNumber = 'SECM_TEMPLATE_HELP_CENTER_PHONE_NUMBER',
  PrivacyPolicyLink = 'SECM_TEMPLATE_PRIVACY_POLICES_LINK',
  TermsAndConditionsLink = 'SECM_TEMPLATE_PRIVACY_POLICES_LINK',
  LearningCenterLink = 'SECM_LEARNING_CENTER_LINK',
  LearningHubLink = 'SECM_LEARNING_HUB_LINK',
}

@Injectable()
export class CountryConfigService {

  private _countryConfig = new BehaviorSubject<ICountryConfig>(null);
  private _countryParametersApiPath = 'v6/ce/countries';

  constructor(private http: HttpCemex, private projectEnv: ProjectSettings, private sessionService: SessionService) {
    if (!this._countryConfig.getValue()) {
      this.setCountryConfiguration();
    }
  }

  public get countryConfig(): Observable<ICountryConfig> {
    return this._countryConfig.asObservable();
  }

  private setCountryConfiguration(): void {
    this.getCountryParameters().subscribe((data: ICountryParameters[]) => {
      let _countryParameters: ICountryParameters;
      let _countryConfig: ICountryConfig;
      if (data && data[0] && data[0].settings) {
        _countryParameters = data[0];
        _countryConfig = {
          countryCode: _countryParameters.countryCode,
          helpCenterEmail: this.getParameterValue(CountryParametersDesc.HelpCenterEmail, _countryParameters),
          helpCenterPhoneNumber: this.getParameterValue(CountryParametersDesc.HelpCenterPhoneNumber, _countryParameters),
          learningCenterLink: this.getParameterValue(CountryParametersDesc.LearningCenterLink, _countryParameters),
          learningHubLink: this.getParameterValue(CountryParametersDesc.LearningHubLink, _countryParameters),
          privacyPoliciesLink: this.getParameterValue(CountryParametersDesc.PrivacyPolicyLink, _countryParameters),
          termsAndConditionsLink: this.getParameterValue(CountryParametersDesc.TermsAndConditionsLink, _countryParameters),
        };
        this._countryConfig.next(_countryConfig);
      }
    });
  }

  private getCountryParameters(): Observable<ICountryParameters[]> {
    const userCountryCode = this.sessionService.userCountry || 'US';
    const url =
      `${this.projectEnv.getBaseOrgEnvPath()}${this._countryParametersApiPath}?countryCode=${userCountryCode}`;
    return this.http.get(url)
      .pipe(map((res) => res.json().countries), catchError(this.handleError));
  }

  private handleError(error) {
    let errMsg: string;
    try {
      const body: any = error.json() || '';
      const errBody = body.error || JSON.stringify(body);
      errMsg = `${errBody.status} - ${errBody.statusText || ''}`;
    } catch (err) {
      errMsg = error.message ? error.message : error.toString();
    }
    return throwError(errMsg);
  }

  private getParameterValue(parameter: string, parameters: ICountryParameters): string {
    const param = parameters.settings.find((s) => s.appParameterDescription === parameter);
    return param ? param.parameterValue : '';
  }

}

import { CustomerDetail } from './customer';
import { Address } from './address';

export class Jobsite {
    public jobsiteId: number;
    public requestId?: number;
    public jobsiteCode?: string;
    public jobsiteDesc?: string;
    public address?: Address;
    public customer?: CustomerDetail;
    public regionId?: number;
    public regionCode?: string;
    public countryCode?: string;
    public addressId?: number;
    public cityDesc?: string;
    public regionDesc?: string;
    public userId?: number;
    public domicilieNum?: number;
    public streetName?: string;
    public postalCode?: string;
    public Latitude?: number;
    public Longitude?: number;
    public customerId?: number;
    public customerCode?: string;
    public customerDesc?: string;
    public isAssigned?: boolean;
    public isRequested?: boolean;
    public hasOptions?: boolean;
    public isChecked?: boolean;
    public editStatus?:string;
    public index?:number;

    // TODO: Replace flags
    public checked?: boolean;
    public visible?: boolean;
}

export class JobsiteRespose {
    public jobsites: Jobsite[];
    public totalCount: number;
}

import { Injectable } from '@angular/core';
import { SessionService } from 'src/app/angular-services-v7/session.service';
import { CountlyService } from '../helpers-v1';
import { UserProfile } from '../models/user-profile';

@Injectable({
  providedIn: 'root',
})
export class CountlyEventsService {
  private userProfile: any;
  private userCountry: string;
  private userAccount: string | number;

  constructor(
    private countly: CountlyService,
    private session: SessionService
  ) {
    this.userProfile = session.userProfile;
    this.userCountry = session.userCountry;
    this.userAccount = this.userProfile.userAccount ? this.userProfile.userAccount.includes('@')
      ? this.userProfile.userAccount
      : this.userProfile.userId
      : this.userProfile.userId;
  }

  public createUserEventSuccess(newUser: string) {
    const event = {
      key: 'AccountCreated',
      count: 1,
      sum: 1,
      segmentation: {
        User: this.userAccount,
        UserId: this.userProfile.userId,
        UserCountry: this.userCountry,
        Customer: this.userProfile.customer
          ? `${this.userProfile.customer.customerCode} - ${this.userProfile.customer.customerDesc}`
          : ``,
        CreatedUser: newUser,
      },
    };
    this.countly.addTracking('add_event', event);
  }

  public createUserEventError(newUser: string, error: string) {
    const event = {
      key: 'AccountCreationError',
      count: 1,
      sum: 1,
      segmentation: {
        Error: error,
        User: this.userAccount,
        UserId: this.userProfile.userId,
        UserCountry: this.userCountry,
        Customer: this.userProfile.customer
          ? `${this.userProfile.customer.customerCode} - ${this.userProfile.customer.customerDesc}`
          : ``,
        NonCreatedUser: newUser,
      },
    };
    this.countly.addTracking('add_event', event);
  }

  public createRequestEventSuccess(newRequest: string, type: string) {
    const event = {
      key: 'RequestCreated',
      count: 1,
      sum: 1,
      segmentation: {
        User: this.userAccount,
        RequestId: newRequest,
        UserCountry: this.userCountry,
        Customer: this.userProfile.customer
          ? `${this.userProfile.customer.customerCode} - ${this.userProfile.customer.customerDesc}`
          : ``,
        Type: type,
      },
    };
    this.countly.addTracking('add_event', event);
  }

  public createRequestEventError(type: string, error: string) {
    const event = {
      key: 'RequestCreationError',
      count: 1,
      sum: 1,
      segmentation: {
        Error: error,
        User: this.userAccount,
        UserCountry: this.userCountry,
        Customer: this.userProfile.customer
          ? `${this.userProfile.customer.customerCode} - ${this.userProfile.customer.customerDesc}`
          : ``,
        Type: type,
      },
    };
    this.countly.addTracking('add_event', event);
  }

  public clickOnBulkEdit() {
    const event = {
      key: 'BulkEditPermissions',
      count: 1,
      sum: 1,
      segmentation: {
        User: this.userAccount,
        UserCountry: this.userCountry,
        Customer: this.userProfile.customer
          ? `${this.userProfile.customer.customerCode} - ${this.userProfile.customer.customerDesc}`
          : ``,
      },
    };
    this.countly.addTracking('add_event', event);
  }

  public copyPermissionsFrom(copiedFrom: any, application: any) {
    const event = {
      key: 'CopyPermissions',
      count: 1,
      sum: 1,
      segmentation: {
        User: this.userAccount,
        UserCountry: this.userCountry,
        Customer: this.userProfile.customer
          ? `${this.userProfile.customer.customerCode} - ${this.userProfile.customer.customerDesc}`
          : ``,
        CopyPermissionsFrom: copiedFrom,
        ApplicationBeingAssigned: application,
      },
    };
    this.countly.addTracking('add_event', event);
  }
}

import { Injectable } from '@angular/core';
import { TranslationService } from '../../../../../../../angular-localization-v7/services/translation.service';
// import { DatePipe } from '@angular/common';
import * as momentImported from 'moment';
import { Observable, throwError } from 'rxjs';

// providers
import { HttpCemex } from '../../../../../../../angular-services-v7/http.service';
import { map, catchError } from 'rxjs/operators';
import {
  IUserNotificationStatisticsItem,
  IUserNotificationCount,
  IUserNotificationNotifications,
  IUserNotificationItem,
} from '../interfaces/user-notification-entry.dto';

const moment: any = momentImported;

@Injectable()
export class UserNotificationService {
  private currentUserNotificationCount: IUserNotificationCount;
  private currentNotificationsList: IUserNotificationItem[];

  constructor(private httpCemex: HttpCemex, private translation: TranslationService) {
  }
  // ----------------------------------------------------------
  public resetStatisticCounters(): IUserNotificationCount {
    this.currentUserNotificationCount = {
      newCount: 0,
      readCount: 0,
      totalCount: 0,
      viewCount: 0,
    };
    return this.currentUserNotificationCount;
  }

  public setStatisticsCounters(v: IUserNotificationStatisticsItem[]): IUserNotificationCount {
    this.resetStatisticCounters();

    v.forEach((i: IUserNotificationStatisticsItem) => {
      switch (i.statusCode) {
        case 'N':
          this.currentUserNotificationCount.newCount = i.count;
          break;
        case 'V':
          this.currentUserNotificationCount.viewCount = i.count;
          break;
        case 'R':
          this.currentUserNotificationCount.readCount = i.count;
          break;

        default:
          break;
      }
    });

    this.currentUserNotificationCount.totalCount =
      this.currentUserNotificationCount.newCount +
      this.currentUserNotificationCount.viewCount +
      this.currentUserNotificationCount.readCount;

    return this.currentUserNotificationCount;
  }

  public setMessageList(v: IUserNotificationNotifications): IUserNotificationItem[] {
    this.currentNotificationsList = [];

    if (v && v.count > 0 && v.items) {
      const today: string = v.serverDate.substring(0, 10);
      let formatDate: string = this.translation.pt('global.formatDate');
      let formatTime: string = this.translation.pt('global.formatTime');

      if (formatDate.startsWith('NOT:')) {
        formatDate = 'L';
      }
      if (formatTime.startsWith('NOT:')) {
        formatTime = 'LT';
      }

      v.items.forEach((it: IUserNotificationItem) => {
        const t1: string = it.processCode.toUpperCase();
        const t2: string = t1 + '.' + it.subprocessCode.toUpperCase();
        const lProcessCode: string = this.translation.pt(t1);
        const lSubProcessCode: string = this.translation.pt(t2);

        const itm: IUserNotificationItem = {
          content: it.content,
          date: it.date,
          isHtmlContent: it.isHtmlContent,
          notificationId: it.notificationId,
          processCode: it.processCode,
          statusCode: it.statusCode,
          subprocessCode: it.subprocessCode,
          summary: it.summary,

          isToday: it.date.startsWith(today),
          localDate: moment
            .utc(it.date)
            .local()
            .format(formatDate),
          localTime: moment
            .utc(it.date)
            .local()
            .format(formatTime),
          // tslint:disable-next-line: object-literal-sort-keys
          descProcessCode: lProcessCode.startsWith('NOT:') ? it.processCode : lProcessCode,
          descSubProcessCode: lSubProcessCode.startsWith('NOT:') ? it.subprocessCode : lSubProcessCode,
        };
        this.currentNotificationsList.push({ ...itm });
      });

      // // #FIXME: in case sort is needed
      // this.currentNotificationsList.sort(
      //   (a: IUserNotificationItem,
      //     b: IUserNotificationItem) => {
      //     if (a.date > b.date) {
      //       return 1;
      //     } else if (a.date < b.date) {
      //       return -1;
      //     } else {
      //       return 0;
      //     }
      //   });

      console.log('setMessageList in OutSort:', this.currentNotificationsList);
    }

    return this.currentNotificationsList;
  }

  public getStatistics(): Observable<IUserNotificationStatisticsItem[]> {
    const apiUrl: string = '/v1/its/notifications/statistics';

    return this.httpCemex
      .get(this.httpCemex.generateEndpoint(apiUrl))
      .pipe(map((res: any) => res.json(), catchError(this.handleError)));
  }

  public getNotificationsPage(pageNumber: number = 1, pageSize: number = 5): Observable<IUserNotificationItem[]> {
    const apiUrl: string = '/v1/its/notifications';

    return this.httpCemex
      .get(this.httpCemex.generateEndpoint(apiUrl) + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize)
      .pipe(map((res: any) => res.json(), catchError(this.handleError)));
  }

  public getNotificationDetail(notificationId: number): Observable<IUserNotificationItem> {
    const apiUrl: string = '/v1/its/notifications';

    return this.httpCemex
      .get(this.httpCemex.generateEndpoint(apiUrl) + '/' + notificationId)
      .pipe(map((res: any) => res.json(), catchError(this.handleError)));
  }

  public setNotificationAsRead(notifId: number): Observable<any> {
    return this.setNotificationStatus(notifId, 'R');
  }
  public setNotificationAsViewed(notifId: number): Observable<any> {
    return this.setNotificationStatus(notifId, 'V');
  }
  public setNewStatusAsViewed(): Observable<any> {
    console.log('SVC setNewStatusAsViewed N-V');

    // return this.setNotificationsChangeStatus(oldStatusCode = "N", newStatusCode = "V");
    return this.setNotificationsChangeStatus('N', 'V');
  }

  private setNotificationStatus(notifId: number, newStatusCode: string): Observable<any> {
    // const apiUrl: string = "/v1/its/notifications";
    // const apiUrl: string = "/v1/its/notifications/" + notifId + "/status/" + newStatusCode;
    const apiUrl: string = '/v1/its/notifications'; //  + notifId + "/status/" + newStatusCode;
    console.log('SVC setNotificationStatus url:', apiUrl);

    return (
      this.httpCemex
        .post(this.httpCemex.generateEndpoint(apiUrl + '/' + notifId + '/status/' + newStatusCode), undefined)
        // + "/" + notificationId)
        .pipe(map((res: Response) => res.json(), catchError(this.handleError)))
    );
  }
  private setNotificationsChangeStatus(
    oldStatusCode: string,
    newStatusCode: string,
  ): Observable<IUserNotificationItem> {
    const apiUrl: string = '/v1/its/notifications';
    const req: any = {
      newStatusCode,
      oldStatusCode,
      // newStatusCode: newStatusCode,
      // oldStatusCode: oldStatusCode,
    };
    console.log('SVC setNotificationsChangeStatus req:', req);

    return (
      this.httpCemex
        .post(this.httpCemex.generateEndpoint(apiUrl), req)
        // + "/" + notificationId)
        .pipe(map((res: Response) => res.json(), catchError(this.handleError)))
    );
  }

  private handleError(error: Response | any): Observable<any> {
    return throwError(error);
  }
}

import { Component, ViewChild, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, finalize } from 'rxjs/operators';

import { CountlyService } from '../helpers-v1';
import { SessionService } from '../angular-services-v7/session.service';
import { Logger } from '../angular-services-v7/logger.service';
import { TranslationService } from 'src/app/angular-localization-v7/services/translation.service';
import { FeatureToggleService } from '../feature-toggle-service/v1/src/feature-toggle.service';
import { ErrorService, LocationService, StorageService, CustomerService, ExternalsystemsService, UserService } from '../services';
import { SsoService } from '../services/sso.service';

import { LocalizationComponent } from '../components/localization/localization.component';

import { AppFeatures } from '../../toggleConfig';
import { Segmentation, Language, LegalEntity, CountryConfiguration } from '../models';

// CMX App Routes
import { baseUrls as ccBaseUrls } from '@cemex-app/cmx-module-cc';
import { WalkmeService } from '../services/walkme.service';
import { DareminderModal } from '../components/modals/dareminder/dareminder.modal';
import { Subscription, combineLatest, forkJoin, interval } from 'rxjs';
import { UserProcessService } from '../services/user-process.service';
import { CountryService } from '../services/country.service';
import { DCMConstants } from '../helpers/DCM.constants';
import { UserProcess, UserProcessCat } from '../models/userProcess';
import { Broadcaster } from '../events-v7/projects/events-v7/src/public_api';
import { B2CService } from '../mfa/services/b2c.service';
import { LanguageService } from '../services/language.service';

class Banner {
  bannerUrl!: string;
  bannerUrlImg!: string;
  isExternal!: boolean;
}
@Component({
  selector: 'app-pages-auth',
  templateUrl: 'auth-pages.component.html',
  styleUrls: ['auth-pages.component.scss'],
})

export class AuthPagesComponent extends LocalizationComponent implements OnInit, OnDestroy {

  @ViewChild('dareminder') dareminder: DareminderModal;
  private subscriptions: Array <Subscription> = [];
  isOnDashboard = false;
  emailErrorMessage: string;
  alertInDOM = false;
  isSelfServiceRegistration = false;
  logo = 'cemex_mono_white.svg';
  forceRouterList: string[] = [];
  countryHasChatbot = false;
  countryHasChatbotv2 = false;
  banner: Banner;
  currentLegalEntity: LegalEntity;
  navVersion = ((window['CX_VERSION'] && (window['CX_VERSION'] === "2")) ? 2 : 3);
  userCountry = '';
  public isCollapsed = false;
  public rtl: boolean;
  public warningModal = false;
  public showNotificationSettingsModal = false;
  public applicationCode = DCMConstants.DCM_APPLICATION_CODE;
  public userProcessCat: UserProcessCat[] = [];
  public userProcesses: UserProcess[] = [];
  private webAppCountriesConfiguration: CountryConfiguration[] = [];
  private countriesConfiguration: CountryConfiguration[] = [];
  private userRoles: any[] = [];
  private noLoginDays = 0;
  private useNotificationPreferences = false;
  public userId: number;
  private userCountryCode = '';
  public notificationSettingProcess = DCMConstants.USER_PROCESSES.FirstLoginNotificationSettings;
  public loginURL;

  @HostListener('document:changeNavVersion', ['$event'])
  changeNavVersion(ev: CustomEvent) {
    // do something meaningful with it
    this.navVersion = (this.navVersion === 2 ? 3 : 2);
    if (this.navVersion === 3) {
      this.isCollapsed = false;
    }
  }

  constructor(
    public errorService: ErrorService,
    public translationService: TranslationService,
    public sessionService: SessionService,
    private countlyService: CountlyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private featureToggleService: FeatureToggleService,
    private locationService: LocationService,
    private storageService: StorageService,
    private customerService: CustomerService,
    private externalSystemsService: ExternalsystemsService,
    private walkmeService: WalkmeService,
    private userProcessService: UserProcessService,
    private countryService: CountryService,
    private userService: UserService,
    private ssoService: SsoService,
    private eventBroadcaster: Broadcaster,
    private b2cService: B2CService,
    private languageService: LanguageService,
  ) {
    super();
    const languageCookie = this.sessionService.readCookie('cmxgo_language');
    const cmx_language = (window as any).CMX_LANGUAGES;
    if (languageCookie && !!cmx_language.find(lang => lang.languageISO == languageCookie)) {
      sessionStorage.setItem('language', languageCookie);
      this.translationService.setLanguage(languageCookie);
      document.cookie = `cmxgo_language; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.cemexgo.com;`;
      this.languageService.updateLanguage(languageCookie);
    }
    else {
      const language = sessionStorage.getItem('language') || localStorage.getItem('language') || window['LANGUAGE'];
      this.languageService.updateLanguage(language, true);
    }

    const temporalLanguage = sessionStorage.getItem('temporalLanguage');
    if(temporalLanguage){
      this.translationService.setLanguage(temporalLanguage);
      sessionStorage.removeItem('temporalLanguage');
    }

    const element = document.getElementsByTagName('body')
    this.isRTL ?
    element[0].setAttribute('dir', 'rtl')
    : element[0].setAttribute('dir', 'ltr');

    combineLatest([
      this.userProcessService.getProcessesCat(),
      this.userProcessService.getUserProcesses(),
      this.userService.getTermsAndConditionsSigned()
    ]).subscribe(([processCat, userProcesses, tAndCSigned]) => {
      this.userProcessCat = processCat;
      this.userProcesses = userProcesses;

      this.userService.getSelfServiceRegistration().subscribe(value => {
        this.isSelfServiceRegistration = value;

        if (!this.isSelfServiceRegistration) {
          this.validateSelfServiceRegistration(this.userProcessCat, this.userProcesses);
        }
      })

      const returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '';
      const userProfile: any = this.sessionService.userProfile;
      this.userCountryCode = this.sessionService.userCountry;
      this.userId = userProfile.userId;

      if (tAndCSigned && !this.isSelfServiceRegistration && !returnUrl && userProfile && userProfile.userId && this.sessionService.userCountry) {
        this.validateNotifications(this.userCountryCode, this.userId);
      }
    }, (error) => {

    });

    this.sessionService.setUserProfile();
    this.startCountly();

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      const currentPath = window.location.pathname;
      this.isOnDashboard = currentPath === '/dashboard';
    });

    this.activatedRoute.queryParams.subscribe((queries) => {
      const currentPath = window.location.pathname;
      this.isOnDashboard = currentPath === '/dashboard';
    });

    // spa ready base urls
    this.forceRouterList = [
      ...(Object.values(ccBaseUrls) as any),
      'user-management',
    ];

    this.userCountry = this.storageService.userCountry;

    if (this.userCountry){
      let countriesSub = forkJoin({
        countries: this.countryService.getCountries(this.userCountry),
        userProcesses: this.ssoService.getUserProcesses(DCMConstants.USER_REGISTRATION_CODE),
      })
      .subscribe(({countries, userProcesses}) => {
        const parameters = countries[0]?.settings;
        const value = parameters.find(p => p.appParameterDescription === DCMConstants.SECM_APP_PARAMETERS.UserRegistration_DefaultDateFormat)?.parameterValue;
        sessionStorage.setItem('defaultDateFormat', value ? value : 'MM/dd/yyyy');

        const systemSource = userProcesses?.Settings?.find(s => s.AppParameterDescription === DCMConstants.SECM_APP_PARAMETERS.UserRegistration_SystemSource);
        const countryCode = userProcesses?.CountryCode?.trim();
        const countrySettings = countryCode + '|' + systemSource?.ParameterValue?.substring(0, 3);
        sessionStorage.setItem('systemSource', '{"settings":["' + countrySettings + '"]}');
      });
      this.subscriptions.push(countriesSub);
    }
  }

  public ngOnInit(): void {
    sessionStorage.removeItem('walkMe_wm-lang');
    this.errorService.internalServerError.subscribe((internalServerError) => {
      if (internalServerError) {
        this.warningModal = true;
      }
    });
    this.featureToggleService.isLoaded$.subscribe(() => {
      this.countryHasChatbot = this.featureToggleService.isEnabledFor(
        AppFeatures.UseChatbot,
        { country: this.storageService.userCountry }
      );
      this.countryHasChatbotv2 = this.featureToggleService.isEnabledFor(
        AppFeatures.UseChatbotv2,
        { country: this.storageService.userCountry }
      );

      const enableNotificationInbox = this.featureToggleService.isEnabledFor(
        AppFeatures.UseNotificationsSettings,
        { country: this.storageService.userCountry }
      );
      sessionStorage.setItem(
        'notificationsFeature',
        enableNotificationInbox ? 'enabled' : 'disabled'
      );
    });

    this.getUserSegmentByCustomerSelected();

    if (localStorage.getItem('platform') === 'PROCORE') {
      const settings: any = {
        externalSystemCode: localStorage.getItem('platform'),
        settings: [
          {
            settingKey: 'AuthCode',
            settingValue: localStorage.getItem('codeExt'),
          },
        ],
      };
      this.externalSystemsService
        .userExternalReferencesInitialSetup(settings)
        .subscribe(
          (data) => {
            console.log(data);
          },
          (error) => {
            console.log(error);
          }
        );
    }

    this.loginURL = this.b2cService.isLoginThroughB2C() ? '/login/b2c' : '/login';
    this.subscriptions.push(this.eventBroadcaster.on<string>(Broadcaster.DCM_APP_LOGOUT).subscribe((response: any) => {
      this.b2cService.logoutB2C();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s?.unsubscribe());
  }

  public goToBannerUrl(): void {
    this.countlyService.addTracking('add_event', {
      'key': 'ClickOnBanner',
      'count': 1,
      'sum': 1,
      'segmentation': {
        'UserId': this.sessionService.userProfile.userId,
        'RedirectURL': this.banner.bannerUrl
      }
    });

    var gotoURL = this.bannerURL();
    if (this.banner.isExternal) {
      window.open(gotoURL, '_blank');
    } else {
      this.locationService.redirect(gotoURL);
    }
  }

  public bannerURL(): string {
    var URL = this.banner.bannerUrl;
    if (this.banner.isExternal) {
      return URL;
    } else {
      const internalUrl = `${location.origin}${URL}`;
      return internalUrl;
    }
  }

  public changeLegalEntity(): void {
    this.currentLegalEntity = this.storageService.selectedLegalEntity;
    const currentLegalEntityId = this.storageService.selectedLegalEntity.legalEntityId;
    this.storageService.legalEntityChanged$.next({ value: currentLegalEntityId, changed: true });
  }

  private setupBanners(segmentationCode: string): void {
    const country = this.storageService.userCountry;
    const countryBanners = window['COUNTRY_BANNERS'];
    let banner: string;
    if (!countryBanners) {
      Logger.error('Error getting banners configuration from CemexGO CDN');
      return;
    }
    if (!country) {
      Logger.error('No country information available for user');
      return;
    }
    banner = `${country}_${segmentationCode}`;
    this.banner = countryBanners[banner];
  }

  private getUserSegmentByCustomerSelected(): void {
    this.sessionService.currentLegalEntity.pipe(filter((le) => le !== null))
      .subscribe((legalEntity) => {
        let segmentationCode = 'DEFAULT';
        this.customerService.getSegmentationsByCustomerCode(legalEntity.legalEntityTypeCode, this.storageService.userCountry).pipe(
          finalize(() => {
            // tslint:disable:max-line-length
            if (this.featureToggleService.isEnabledFor(AppFeatures.UseMarketingBanner, { country: this.storageService.userCountry })) {
              this.setupBanners(segmentationCode);
            }
          }))
          .subscribe((segmentations: Segmentation[]) => {
            let segmentation: Segmentation;
            let subSegmentation: Segmentation;

            segmentation = segmentations.filter((s) => s.segmentationType.segmentationTypeId === 1)[0];
            if (segmentation) {
              segmentationCode = segmentation.segmentationCode;
              sessionStorage.setItem('userSegment', segmentationCode);
            }
            subSegmentation = segmentations.filter((s) => s.segmentationType.segmentationTypeId === 2)[0];
            if (subSegmentation) {
              sessionStorage.setItem('userSubsegment', subSegmentation.segmentationCode);
            }
          }, (error: any) => {
            Logger.error('Error while fetching customer segmentations: ' + error);
          });
      });
  }

  private startCountly(): void {
    const userId = (this.sessionService.userProfile?.userId) ? this.sessionService.userProfile.userId.toString() : '0';
    const countlyKey = window['COUNTLY_KEY'];
    const countlyUrl = window['COUNTLY_URL'];
    const user = this.getUserInfoForCountly();
    this.countlyService.startService(countlyUrl, countlyKey);
    if (this.countlyService.Countly && user) {
      this.countlyService.Countly.app_version = '2.0';
      this.countlyService.changeId('', userId);
      this.countlyService.identifyUser(user);
      this.countlyService.init();
    }
  }

  private getUserInfoForCountly() {
    const user = this.sessionService.userProfile;
    if (user) {
      return {
        email: '',
        // if user has email as userAccount,so use userAcount, otherwise user UserId
        userId: user.userAccount ? (user.userAccount.indexOf('@') > -1) ? user.userAccount : user.userId : user.userId,
        name: user.userAccount ? (user.userAccount.indexOf('@') > -1) ? user.userAccount : user.userId : user.userId,
        phone: '',
        username: ''
      };
    } else {
      return;
    }
  }

  private getUserProcessByName(processName: string) {
    const processId = this.userProcessCat.find(p => p.processName === processName)?.processId;
    return this.userProcesses.find(up => up.processId === processId);
  }

  private validateNotifications(countryCode: string, userId: number) {
    let sValidations =combineLatest([
      this.countryService.getCountriesByAppCountry(countryCode, DCMConstants.APPLICATION_CODES.DCMWebTool_App),
      this.countryService.getCountries(null),
      this.userService.getUserV6(userId, 'roles'),
      this.countryService.getCountryConfigurationByParameter('XX', DCMConstants.DIGITALADMIN_SETTINGS.DA_No_Login_Days),
      this.featureToggleService.isLoaded$
    ])
      .subscribe(([webAppCountriesConfig, countriesConfig, userInfo, parameterNoLoginDays]) => {
        this.webAppCountriesConfiguration = webAppCountriesConfig;
        this.countriesConfiguration = countriesConfig;
        this.userRoles = userInfo.roles;

        this.useNotificationPreferences = this.featureToggleService.isEnabledFor(
          AppFeatures.UseNotificationSettingsPreferences,
          { country: this.storageService.userCountry }
        );

        if (this.getUserProcessByName(this.notificationSettingProcess) && this.useNotificationPreferences) {
          this.showNotificationSettingsModal = true;
          sessionStorage.setItem('notification_settings', this.showNotificationSettingsModal.toString());
          return;
        }
        if(sessionStorage.getItem('notification_settings') !== 'true') {
          const userCountryConfiguration = this.webAppCountriesConfiguration.find(s => s.countryCode.toString().trim() === this.userCountryCode);
          const xxCountryConfiguration = this.countriesConfiguration.find(s => s.countryCode.toString().trim() === 'XX');
          this.noLoginDays = parameterNoLoginDays[0].parameterValue ? parseInt(parameterNoLoginDays[0].parameterValue) : 0;
          this.showDAReminder(userCountryConfiguration, xxCountryConfiguration, this.userRoles, this.noLoginDays);
        }
      }, (error) => {
        this.activateNotifications();
      });
    this.subscriptions.push(sValidations);
  }

  private showDAReminder(userCountryConfiguration, xxCountryConfiguration, userRoles, noLoginDays) {
    try {
      const webReminderId = this.userProcessCat.find(p => p.processName === DCMConstants.USER_PROCESSES.DateOfWebReminder).processId;
      const userProcess = this.userProcesses.find(up => up.processId === webReminderId);
      const settingsxx = xxCountryConfiguration?.settings;
      const settingsUser = userCountryConfiguration?.settings;
      const reminderToDA = settingsUser.find(s => s.appParameterDescription === DCMConstants.SECM_APP_PARAMETERS.DigitalAdmin_ReminderWeb);
      if (!(reminderToDA.parameterValue === '1') || !userRoles.some(r => r.roleCode === DCMConstants.DIGITALADMIN_SETTINGS.DA_RoleCode)) {
        this.activateNotifications();
        return;
      }
      const daReminderDays = settingsxx.find(s => s.appParameterDescription === DCMConstants.SECM_APP_PARAMETERS.Maintenance_DAReminderDays);
      const dateProcess = new Date(userProcess.dateProcess);
      const daysToValidateReminder = Number(daReminderDays.parameterValue);
      const daysSinceLastReminder = this.dateDiffInDays(dateProcess, new Date());
      if (daysSinceLastReminder < daysToValidateReminder) {
        this.activateNotifications();
        return;
      }

      const customer: any = this.storageService.defaultCustomer;
      if (!customer || !customer.customerId) {
        this.activateNotifications();
        return;
      }

      let sGetUsersByCustomer = this.customerService.getUsersByCustomer(customer.customerId).subscribe(data => {
        const user = JSON.parse(sessionStorage.getItem('user_profile'));
        const users = data.filter((userList) => userList.userId !== user.userId);
        if(users.find(user => !user.dateLogin) !== undefined ||
        users.find(user => this.dateDiffInDays(new Date(user.dateLogin), new Date()) > noLoginDays) !== undefined) {
          this.userProcessService.updateUserProcess(userProcess.processId).subscribe();
          this.dareminder.open();
        }
        else {
          this.activateNotifications();
        }
      }, (error) => {
        this.activateNotifications();
      });
      this.subscriptions.push(sGetUsersByCustomer);
    } catch {
      this.activateNotifications();
    }
  }

  private validateSelfServiceRegistration(processesCat: any, userProcesses: any) {
    const selfServiceRegistrationId = processesCat.find(p => p.processName === DCMConstants.USER_PROCESSES.SelfServiceRegistration)?.processId;
    const userProcess = userProcesses.find(up => up.processId === selfServiceRegistrationId);

    if (userProcess !== undefined && userProcess !== null) {
      this.isSelfServiceRegistration = true;
      this.userService.setSelfServiceRegistration(this.isSelfServiceRegistration);
    }
  }

  private activateNotifications() {
    this.walkmeService.initializeService();
  }

  private dateDiffInDays(olderDate, newerDate) {
    const MilisecondsPerDay = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(olderDate.getFullYear(), olderDate.getMonth(), olderDate.getDate());
    const utc2 = Date.UTC(newerDate.getFullYear(), newerDate.getMonth(), newerDate.getDate());
    return Math.floor((utc2 - utc1) / MilisecondsPerDay);
  }

  sidenavToggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  public closeNotifications() {
    this.showNotificationSettingsModal = false;
    if(sessionStorage.getItem('notification_settings') !== 'true') {
      const userCountryConfiguration = this.webAppCountriesConfiguration.find(s => s.countryCode.toString().trim() === this.userCountryCode);
      const xxCountryConfiguration = this.countriesConfiguration.find(s => s.countryCode.toString().trim() === 'XX');
      this.showDAReminder(userCountryConfiguration, xxCountryConfiguration, this.userRoles, this.noLoginDays);
    } else {
      this.activateNotifications();
    }
  }
}

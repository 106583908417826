import { MultiEmailValidators } from './../../helpers/validators/multi-email.validator';
import {
  IUserNotificationStatisticsItem,
  IUserNotificationCount,
  IUserNotificationItem,
  IUserNotificationModules,
  IUserNotificationModuleDto,
} from './../../interfaces/user-notification-entry.dto';
import { Component, OnInit, ViewChild, Optional, Inject } from '@angular/core';
import { TranslationService } from 'src/app/angular-localization-v7/services/translation.service';
import { StorageService, ErrorService } from '../../services';
import { LocalizationComponent } from '../../components/localization/localization.component';
import { Language } from '../../models';
import { UserNotificationV2Service } from '../../services/user-notification.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'user-notifications',
  templateUrl: 'user-notifications.component.html',
  styleUrls: ['user-notifications.component.scss'],
})
export class UserNotificationsComponent extends LocalizationComponent implements OnInit {
  successMsg = '';
  languages: Language[] = [];
  placeholder = '';
  breadCrumbs: any[] = [];
  userCountry = '';
  analyticsAccepted = false;
  notifications: Notification[] = [];
  change: any;

  userNotifications: Notification[] = [];

  currentLanguage: Language;
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  allNotificationsChecked = false;
  userNotificationsEnabled = false;

  // Page information
  _localLanguageISO: string;
  _monthPage: any = new Date().getMonth();
  _pageNow: number = 1;
  _monthNow: number = new Date().getMonth();
  _pageSize: number = 10;
  _pageMax: number = 1;
  _msgCount: number = 0; // total messages
  _msgCountN: number = 0;
  _msgCountV: number = 0;
  _msgCountR: number = 0;
  _groupToday: number = 0;
  _groupMonth: number = 0;
  _groupPrevious: number = 0;

  _viewingNotification: IUserNotificationItem;
  _userNotificationShortList: IUserNotificationItem[];
  _shareEmailAddress: string = '';
  _shareUserMessage: string = '';

  _userNotificationModules: IUserNotificationModules;
  _userNotificationProcessModules: IUserNotificationModuleDto[] = [];
  _userNotificationSubprocessModules: IUserNotificationModuleDto[] = [];

  _selectedUserDate: string = '';
  _selectedProcessCode: string = '';
  _selectedSubprocessCode: string = '';

  byNow:any;

  // Share notifications
  shareForm: FormGroup;
  shareEmailsOnError: string;
  shareEmailsReceivers: string;

  shareAlertVisible: boolean = false;
  shareAlertError: boolean = false;
  sendingUserNotification: boolean = false;

  // #FIXME: retirar testigos
  private _userNotificationUnreadCount: number;
  private _userNotificationTotalCount: number;

  public notificationDetailDialog = false;
  public notificationShareSuccess = false;
  public notificationShareDialog = false;
  @ViewChild('email') emailInput: {
    el: { writeValue: (arg0: string) => void };
  };
  @ViewChild('message') messageInput: {
    el: { writeValue: (arg0: string) => void };
  };

  constructor(
    public translationService: TranslationService,
    private storageService: StorageService,
    public userNotificationService: UserNotificationV2Service,
    private fb: FormBuilder // *******************************
  ) {
    super();

    this.shareForm = this.fb.group({
      email: [
        null,
        Validators.compose([
          Validators.required,
          MultiEmailValidators.emailList,
        ]),
      ],
      message: [null, Validators.required],
    });
    this.shareForm.valueChanges.subscribe((f) => {
      this._shareEmailAddress = f.email;
      this._shareUserMessage = f.message;
      this.shareEmailsOnError = this.emailsNotRecognized(this._shareEmailAddress);
      this.shareEmailsReceivers = this.emailsRecognized(this._shareEmailAddress);
      this._shareEmailAddress = this.shareEmailsReceivers;
    });
  }

  onEmailInput(event: { detail: any }) {
    this.shareForm.setValue({
      email: event.detail,
      message: this.shareForm.value.message,
    });
  }

  onMessageInput($event: { detail: any }) {
    this.shareForm.setValue({
      email: this.shareForm.value.email,
      message: $event.detail,
    });
  }

  emailsNotRecognized(emails: string) {
    // tslint:disable-next-line: max-line-length
    const regex =
      /^(([^±!@£$%^&*|,´ñÑáéíóúÁÉÍÓÚäëïöüåÄËÏÖÜÅýÝ~+§¡!€#¢§¶¬"°•ªº{}()«\\/=<>?¿:;\s@"]+(\.[^±!@£$%^&*'|,´ñÑáéíóúÁÉÍÓÚäëïöüåÄËÏÖÜÅýÝ~+§¡!€#¢§¶¬"°•ªº{}()«\\/=<>?¿:;\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emails) {
      const emailList = emails.replace(/[; :]/g, ',').split(',').filter((v) => v && !regex.test(v));
      const emailMessage = emailList.length ? emailList.join(' - ') : null;
      return emailMessage;
    } else {
      return null;
    }
  }

  emailsRecognized(emails: string) {
    // tslint:disable-next-line: max-line-length
    const regex =
      /^(([^±!@£$%^&*|,´ñÑáéíóúÁÉÍÓÚäëïöüåÄËÏÖÜÅýÝ~+§¡!€#¢§¶¬"°•ªº{}()«\\/=<>?¿:;\s@"]+(\.[^±!@£$%^&*'|,´ñÑáéíóúÁÉÍÓÚäëïöüåÄËÏÖÜÅýÝ~+§¡!€#¢§¶¬"°•ªº{}()«\\/=<>?¿:;\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emails) {
      const emailList = emails.replace(/[; :]/g, ',').split(',').filter((v) => v && regex.test(v));
      const emailMessage = emailList.length ? emailList.join(',') : null;
      return emailMessage;
    } else {
      return null;
    }
  }

  ngOnInit() {
    this.languages = window as { [key: string]: any }['CMX_LANGUAGES'];
    this.languages = this.languages['CMX_LANGUAGES'];
    this.userCountry =
      this.storageService.getFromSessionStorage('country') ||
      this.storageService.getFromLocalStorage('country') ||
      '';
    this.breadCrumbs = [
      { item: 'views.userNotifications.homeRoute', link: '/dashboard' },
      { item: 'views.settings.title' },
    ];
    this.currentLanguage = this.translationService.selectedLanguage;

    // for: user-notification
    this.retrieveUserNotificationModules();
    this._localLanguageISO = sessionStorage.getItem('language');
    let localLanguage = this._localLanguageISO.split('_');
    moment.locale(localLanguage[0]);
    this.refetchNotificationsPage();
  }

  public notificationShareDialogClose() {
    this.notificationShareDialog = false;
    if (!this.sendingUserNotification) {
      return;
    }
    if (this.shareAlertError) {
      window.scrollTo(0, 0);
      this.shareAlertVisible = true;
    } else {
      this.notificationShareSuccess = true;
    }
  }

  public refetchNotificationsCount(): void {
    this.userNotificationService.getStatistics().subscribe((v: IUserNotificationStatisticsItem[]) => {
        let stats: IUserNotificationCount = this.userNotificationService.resetStatisticCounters();
        stats = this.userNotificationService.setStatisticsCounters(v);
        this._userNotificationUnreadCount = stats.newCount;
        this._userNotificationTotalCount = stats.totalCount ? stats.totalCount : 0;
      },
      (err: any) => {
        console.error('its page 1 error:', err);
      }
    );
  }

  public refetchNotificationsPage(page = 1): void {
    this.userNotificationService.getNotificationsPage(this._pageNow, this._pageSize, this._selectedUserDate, this._selectedUserDate,
      this._selectedProcessCode, this._selectedSubprocessCode).subscribe((v: any) => {
        this._msgCount = v.count;
        const addLastPage = this._msgCount % this._pageSize ? 1 : 0;
        this._pageMax = Math.floor(this._msgCount / this._pageSize) + addLastPage;
        const vList: IUserNotificationItem[] = this.userNotificationService.setMessageList(v);
        this._userNotificationShortList = [...vList];
        this._userNotificationTotalCount = vList.length;
        this._groupToday = vList.filter((it) => it.group === 'D').length;
        this._groupMonth = vList.filter((it) => it.group === 'M').length;
        this._groupPrevious = vList.filter((it) => it.group === 'P').length;
      },
      (err: any) => {
        console.error('its page 1 error:', err);
      },
      () => {
        return;
      }
    );
  }

  filterDateCancel() {
    this._pageNow = 1;
    this._selectedUserDate = undefined;
    this.refetchNotificationsPage();
  }

  filterDateSelect(date: any) {
    let momentObj = moment(date, 'LL');
    this._pageNow = 1;
    this._selectedUserDate = momentObj.toString();
    this.refetchNotificationsPage();
  }

  changePageTo(v: number) {
    this._pageNow = v;
    this.refetchNotificationsPage(this._pageNow);
  }

  public showUserNotificationShare(n: IUserNotificationItem): void {
    this._viewingNotification = n;
    this.shareForm.setValue({ email: '', message: '' });
    this.emailInput.el.writeValue('');
    this.messageInput.el.writeValue('');
    this.sendingUserNotification = false;
    this.notificationShareDialog = true;
  }

  public showUserNotificationDetail(n: IUserNotificationItem): void {
    this._viewingNotification = n;
    this.userNotificationService.getNotificationDetail(n.notificationId).subscribe((v: IUserNotificationItem) => {
      this._viewingNotification = v;
      this.notificationDetailDialog = true;
      this.userNotificationService.setNotificationAsRead(n.notificationId).subscribe((res: Response) => {
          return;
        },
        (err: ErrorService) => {
          console.error('SVC ERR setNotificationAsRead:', err);
        }
      );
    },(err: any) => {
      console.error('SVC showUserNotificationDetail error:', err);
    });
  }

  public sendUserNotificationShare() {
    this.sendingUserNotification = true;
    this.userNotificationService.shareNotification( this._viewingNotification.notificationId, this._shareEmailAddress, this._shareUserMessage)
      .subscribe((res: Response) => {
          // console.log('SVC RES shareNotification:', res);
          this.shareAlertError = false;
          this.notificationShareDialogClose();
        },
        (err: ErrorService) => {
          console.error('SVC ERR shareNotification:', err);
          this.shareAlertError = true;
          this.notificationShareDialogClose();
        });
  }

  private retrieveUserNotificationModules(): void {
    this.userNotificationService.getModules().subscribe((v: IUserNotificationModules) => {
      this._userNotificationModules = v;
      this._userNotificationModules.processItems.forEach((p) => {
        const pDesc = this.translationService.pt(p.code);
        if (!pDesc.startsWith('NOT:')) p.desc = pDesc;
        p.subprocessItems.forEach((s) => {
          const sDesc = this.translationService.pt(p.code + '.' + s.code);
          if (!sDesc.startsWith('NOT:')) s.desc = sDesc;
        });
      });
      this.setProcessModules();
    });
  }

  public setProcessModules(): void {
    this._userNotificationModules.processItems.forEach((p) => {
      this._userNotificationProcessModules.push(p);
    });
  }

  public selectSubprocess(): void {
    this._pageNow = 1;
    this.refetchNotificationsPage();
  }

  public processSelected(event: CustomEvent) {
    this._selectedProcessCode = event.detail || '';
    this.setSubprocessModules();
  }

  public subprocessSelected(event) {
      this._selectedSubprocessCode = event.detail || '';
      this.selectSubprocess();
  }

  public setSubprocessModules(): void {
    this._pageNow = 1;
    this._userNotificationSubprocessModules = [];

    this._userNotificationModules.processItems.forEach((p) => {
      if (p.code == this._selectedProcessCode) {
        p.subprocessItems.forEach((s) => {
          this._userNotificationSubprocessModules.push(s);
        });
      }
    });

    this._selectedSubprocessCode = '';
    this.refetchNotificationsPage();
  }

  public userNotificationStatusClasses(
    n: IUserNotificationItem,
    c: string = ''
  ) {
    switch (n.statusCode) {
      case 'N':
        c += ' user-notifications__entry-data-new';
        break;
      case 'V':
        c += ' user-notifications__entry-data-seen';
        break;
      case 'R':
        c += ' user-notifications__entry-data-read';
        break;

      default:
        break;
    }
    // console.log("notification status class: ", c);

    return c;
  }

  onDomChange(change: MutationRecord): void {
    const firstNode = change.removedNodes[0];
    if (firstNode && firstNode.nodeName === 'CMX-ALERT') {
      this.shareAlertVisible = false;
    }
  }
}

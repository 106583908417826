import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/angular-services-v7/session.service';
import CustomerInfoAppLauncher from '@cemex-app/cmx-module-cc';
import { AppLauncher, Environment } from './types';
import { EnvironmentService, EnvironmentStructure } from '../../services';
import { SpaFunctions } from '../../helpers/functions';
import { ApplicationCode } from '../../spa';
import { WalkmeService } from 'src/app/services/walkme.service';

const PFX = '[CustomerInfoLauncher]';

@Component({
  selector: 'app-customer-info-launcher',
  templateUrl: './customer-info-launcher.component.html',
  styleUrls: ['./customer-info-launcher.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerInfoLauncherComponent implements OnInit {
  private _selector: string;

  constructor(
    elem: ElementRef,
    private activatedRoute: ActivatedRoute,
    private environmentService: EnvironmentService,
    private router: Router,
    private functions: SpaFunctions,
    public sessionService: SessionService,
    private walkmeService: WalkmeService,
  ) {
    this.registerLauncher(new CustomerInfoAppLauncher());
    this._selector = elem.nativeElement.tagName.toLowerCase();
  }

  public ngOnInit() {
    // Prepare the DOM for mounting the application
    const rootNode = document.querySelector(this._selector);
    const mountElement = this.createMountElement();
    rootNode.appendChild(mountElement);
    // Get environment variables to bootstrap the application
    this.environmentService
      .getEnvironmentConfig('P1', EnvironmentStructure.JSON)
      .subscribe(
        (env) => {
          this.registerSessionService(this.sessionService);
          this.initReactApp(this.createEnvironment(env), mountElement);
        },
        () =>
          console.log(
            PFX,
            'Init aborted. Could not set up environment configuration.'
          )
      );
      this.walkmeService.initializeService();
  }

  /**
   * @description
   *
   * Registers the application product launcher
   *
   */
  private registerLauncher(launcher: string): void {
    this.functions.registerGlobal(
      ApplicationCode.CUSTOMER_INFO,
      ['productLauncher'],
      launcher
    );
  }

  /**
   * @description
   *
   * Synchronizes history changes from inside the React application with Angular Router.
   *
   */
  private subscribeToHistory(): void {
    const history = this.functions.extractGlobal(
      ApplicationCode.CUSTOMER_INFO,
      ['browserHistory']
    );
    if (history) {
      history.listen(({ pathname, state }, action) => {
        if (
          !pathname.includes(this.getActivatedRoutePathname()) &&
          !(state && state.ngSimulated)
        ) {
          this.router.navigate([pathname]);
        }
      });
    } else {
      console.log(PFX, 'Could not attach listener to browser history.');
    }
  }

  /**
   * @description
   *
   * Initializes the React application from the registered product launcher.
   *
   */
  private initReactApp(
    environment: Environment,
    mountElement: HTMLElement
  ): void {
    this.functions
      .extractGlobal(ApplicationCode.CUSTOMER_INFO, ['productLauncher'])
      .init(mountElement, { environment }, () => {
        this.subscribeToHistory();
        console.log(PFX, 'App inited');
      });
  }

  /**
   * @description
   *
   * Registers the session service from which the React application will be listening
   * to legal entity changes.
   *
   */
  private registerSessionService(sessionService: any): void {
    this.functions.registerGlobal(
      ApplicationCode.USER_PROVISIONING,
      ['sessionService'],
      sessionService
    );
  }

  /**
   * @description
   *
   * Creates the environment object needed to bootstrap the React application.
   * @param env
   *
   */
  private createEnvironment(env): Environment {
    const environment: Environment = {
      apiHost: env['API_HOST'],
      appFeatures: 'api',
      basePath: '/commercialconditions',
      entryPath: this.getActivatedRoutePathname(),
      build: '',
      countlyKey: {
        CC: env['COUNTLY_KEY'] || '',
        CI: env['COUNTLY_KEY'] || '',
      },
      dcmHost: env['DCM_HOST'] || '',
      environment: env['ENV'] || 'quality',
      googleMapsApiKey: env['GOOGLE_MAPS_API_KEY'] || '',
      rolloutKey: env['ROLLOUT_APP_KEY'] || '',
      ibmClientId: env['CLIENT_ID'] || '',
      production: true,
      translations: {
        locale: env['LANGUAGE'],
        fallbackLocale: 'en_US',
        translateUrl: env['TRANSLATE_URL'],
      },
    };
    return environment;
  }

  /**
   * @description
   *
   * Creates the mount element where the React application will render.
   *
   */
  private createMountElement(): HTMLElement {
    const element = document.createElement('div');
    element.id = 'react-app';
    return element;
  }

  /**
   * @description
   *
   * Retrieves the pathname from the activated route, which will be the entry point
   * for the React application.
   *
   */
  private getActivatedRoutePathname(): string {
    return `/${this.activatedRoute.snapshot.url.join('/')}`;
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookiebotUser } from '../models';


@Injectable({
  providedIn: 'root'
})
export class CookiebotUserService {
  private apiEndpoint: string = (window as any).API_HOST || '';
  private apiKey: string = (window as any).CLIENT_ID || '';
  private appCode: string = (window as any).APP_CODE || '';

  constructor(private http: HttpClient) { }

  public saveConsentLogger(body: CookiebotUser): Observable<any> {
    const uri = this.apiEndpoint + 'v5/secm/audit/log';
    const headers = { headers: this.getHeaders() };
    return this.http.post(uri, body, headers);
  }

  private getHeaders(): HttpHeaders {
    const tokens = JSON.parse(sessionStorage.getItem('token_data'));
    return new HttpHeaders({
      authorization: `Bearer ${tokens.oauth2.access_token}`,
      jwt: tokens.jwt,
      'app-code': this.appCode,
      'x-ibm-client-id': this.apiKey,
      'accept-language': 'en-US',
    });
  }

}

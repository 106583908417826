import { Component, OnInit } from '@angular/core';
import { DocumentService } from '../../../services';
import { DomSanitizer } from '@angular/platform-browser';
import { DCMConstants } from '../../../helpers/DCM.constants';
import { CountryService } from '../../../services/country.service';
import { combineLatest } from 'rxjs';
import { WalkmeService } from '../../../services/walkme.service';
import { SessionService } from 'src/app/angular-services-v7/session.service';

@Component({
  selector: 'dareminder-modal',
  templateUrl: './dareminder.modal.html',
  styleUrls: ['./dareminder.modal.scss']
})
export class DareminderModal implements OnInit {

  public dareminder = false;
  public body;

  constructor(
    private documentService: DocumentService,
    private countryService: CountryService,
    private walkmeService: WalkmeService,
    private sessionService: SessionService,
    private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
  }

  public dareminderClosed() {
    this.dareminder = false;
    this.walkmeService.initializeService();
  };

  public open() {
    const userCountryCode = this.sessionService.userCountry.trim();
    combineLatest([
      this.countryService.getCountries(userCountryCode),
      this.countryService.getCountries(null),
    ]).subscribe(([_countriesUser, _countriesXX]) => {
      try {
        const countryUser = _countriesUser.find(s => s.countryCode.toString().trim() === userCountryCode);
        const countryXX = _countriesXX.find(s => s.countryCode.toString().trim() === 'XX');
        const imgUrl = countryXX.settings.find(s => s.appParameterDescription === DCMConstants.SECM_APP_PARAMETERS.DigitalAdmin_ReminderImgUrl);
        const cemexgoLink = countryUser.settings.find(s => s.appParameterDescription === DCMConstants.SECM_APP_PARAMETERS.Template_CemexGoLink);
        const parameters = [
          {
            parameterName: DCMConstants.SECM_APP_PARAMETERS.DigitalAdmin_ReminderImgUrl,
            parameterValue: imgUrl.parameterValue.replace('{CountryCode}', userCountryCode)
          },
          {
            parameterName: DCMConstants.SECM_APP_PARAMETERS.Template_CemexGoLink,
            parameterValue: cemexgoLink.parameterValue
          }
        ];

        this.documentService.getDocumentLanguageWithParameters(DCMConstants.DIGITALADMIN_SETTINGS.DAReminder_MessageCode, userCountryCode, false, parameters)
          .subscribe((response) => {
            try {
              const _body = response[0].htmlDocument.toString();
              this.body = this.sanitizer.bypassSecurityTrustHtml(_body);
              this.dareminder = true;
            } catch {
              this.walkmeService.initializeService();
            }
          });
      } catch {
        this.walkmeService.initializeService();
      }
    });
  }

  public close() {
    this.dareminder = false;
  }

}

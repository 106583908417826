import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Jobsite } from '../models';
import { CmxHttpClient } from './http-client.service';

@Injectable()
export class JobsiteService {
  constructor(private cmxHttp: CmxHttpClient) { }
  // TODO: DEPRECATE
  getJobsitesForUser(userId: number) {
    return this.cmxHttp.get<Jobsite[]>('v5/secm/user/' + userId + '/data/jobsites?pageSize=0&pageNumber=0')
      .pipe(map((res) => res['jobsites'] ? res['jobsites'] : []));
  }

  getJobsitesByCustomer(customerId: number) {
    const uri = `v5/secm/user/data/customers/_CUSTOM_/jobsites?customersId=${customerId.toString()}&allJobsites=true`;
    return this.cmxHttp.get<Jobsite[]>(uri).pipe(map((res) => res['jobsites'] ? res['jobsites'] : []));
  }

  getUsersByJobsite(jobsiteId: number) {
    const uri = `v6/secm/jobsites/${jobsiteId}/users?accountType=U&userType=C&isDigitalAdmin=false`;
    return this.cmxHttp.get(uri).pipe(map((res) => res['users'] ? res['users'] : []));
  }

  // TODO: DEPRECATE
  getUserJobsitesForCustomers(customersCodes: string) {
    const uri = `v5/secm/user/data/customers/_CUSTOM_/jobsites?customersId=${customersCodes}&allJobsites=true`;
    return this.cmxHttp.get<Jobsite[]>(uri).pipe(map((res) => res['jobsites'] ? res['jobsites'] : []));
  }
}

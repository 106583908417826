import { Injectable } from '@angular/core';
import * as moment from 'moment/moment';

@Injectable()
export class AuthService {
  /**
   * OAuth access token
   * @returns {string}
   */
  get accessToken(): string {
    return sessionStorage.getItem('access_token')as string;
  }

  set accessToken(access_token: string) {
    sessionStorage.setItem('access_token', access_token);
  }

  /**
   * Getter for the JWT authentication token, which is stored in sessionStorage
   * @returns {string}
   */
  get jwtToken(): string {
    return sessionStorage.getItem('jwt') as string;
  }

  set jwtToken(jwt: string) {
    sessionStorage.setItem('jwt', jwt);
  }

  get refreshToken(): string {
    return sessionStorage.getItem('refresh_token')as string;
  }

  set refreshToken(refresh_token: string) {
    sessionStorage.setItem('refresh_token', refresh_token);
  }

  set dto(data: any) {
    sessionStorage.setItem('loginDTO', JSON.stringify(data));
  }

  get dto(): any {
    return JSON.parse(sessionStorage.getItem('loginDTO')as string);
  }

  get expiryDate(): string {
    return sessionStorage.getItem('expiry_date')as string;
  }

  set expiryDate(expiry_date: string) {
    sessionStorage.setItem('expiry_date', expiry_date);
  }

  get isLoggedin(): boolean {
    return !!sessionStorage.getItem('refresh_token') && !!sessionStorage.getItem('access_token');
  }

  get isTokenExpired(): boolean {
    return moment().isBefore(this.getExpiration());
  }

  getExpiration() {
    const expiration = sessionStorage.getItem('expiry_date');
    const expiresAt = JSON.parse(expiration) ;
    return moment(expiresAt);
  }

  clearStorage() {
    sessionStorage.clear();
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionService } from 'src/app/angular-services-v7/session.service';
import { Application } from '../models';

@Injectable()
export class AdminAuthGuard implements CanActivate {

    public user;

    constructor(private sessionService: SessionService, private router: Router) {
        this.user = sessionService.userProfile;
    }

    public canActivate() {
        const userApps = this.userApplications;
        if (userApps.length > 0) {
            const userMgtApp = userApps.filter((apps) => apps.applicationCode === 'UserProvisioning_App')[0];
            const driverMgtApp =
                userApps.filter((apps) => apps.applicationCode === 'Drivers_Console')[0];
            if (driverMgtApp) {
                if (driverMgtApp.roles.some((r) => r.roleCode === 'DTM_ADMIN_CARR_WEB')) { // Hauler Mgr
                    return true;
                } else {
                    this.noAccess();
                }
            } else if (userMgtApp) {
                if (userMgtApp.roles.some((r) => r.roleCode === 'SECMSTAFFUSR' // Staff
                    || r.roleCode === 'SECMORGUSR' // Power User
                    || r.roleCode === 'SECMORGROOT') // DA
                    && this.user.userType === 'C' // only Customers
                ) {
                    return true;
                } else {
                    this.noAccess();
                }
            } else {
                this.noAccess();
            }
        } else {
            this.noAccess();
        }
    }

    private noAccess(): boolean {
        this.router.navigate(['/dashboard']);
        return false;
    }

    private get userApplications() {
        const userApps = sessionStorage.getItem('user_applications');
        const tmpUserApps: Application[] = this.convertJsonTo<Application[]>(userApps);
        if (tmpUserApps) {
            return tmpUserApps;
        } else {
            return [];
        }

    }

    private convertJsonTo<T>(obj: string): T {
        try {
            const tmpJson = JSON.parse(obj);
            return tmpJson as T;
        } catch (e) {
        }
        return null;
    }
}
